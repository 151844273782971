<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="">
        <ion-grid>
          <ion-row class="align-center" id="row">
            <ion-col class="d-flex" size="9">
              <ion-label class="ml-2 fs-3 fw-500">{{ $t('orderB2b.paymentStatus') }}</ion-label>
            </ion-col>
            <ion-col v-if="paymentMethodName === 'paynow' || isShowCloseButton">
              <ion-buttons class="ion-float-right" @click="onCloseButton()">
                <ion-icon size="small" :icon="close"></ion-icon>
              </ion-buttons>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-y="true" :scroll-events="true" v-if="!loading">
      <!-- PAYMENT PENDING -->
      <div
        v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.PENDING && paymentMethodName === 'paynow'"
        class="align-items-center mt-3"
      >
        <ion-img
          :src="PAYMENT_STATUS_ICONS[paymentInfo.status.toUpperCase()]"
          class="payment-status-icon mt-4"
        />
        <ion-text color="warning" class="my-4 ion-text-center">
          <h2>{{ $t('orderB2b.paymentPending') }}</h2>
        </ion-text>
        <ion-row class="mt-5 ion-padding-horizontal">
          <ion-text>
            {{ $t('orderB2b.paymentPendingMessage') }}
          </ion-text>
        </ion-row>
        <ion-row class="mt-3 ion-padding-horizontal">
          <ion-text>
            <strong>{{ $t('orderB2b.paymentPendingHeadline') }}</strong>
          </ion-text>
        </ion-row>
        <ion-row class="ion-padding-horizontal ion-text-uppercase mt-1">
          <ion-text>
            <strong>{{ dayjs.unix(paymentInfo.expires_at).format('hh:mm A, DD MMM YYYY') }}</strong>
          </ion-text>
        </ion-row>
        <ion-row class="ion-padding-horizontal ion-text-uppercase mt-3 align-items-center">
          <ion-img class="expiring-icon ion-float-left ml-3" src="/assets/icon/payment/timer.svg" />
          <ion-text color="danger" class="ion-padding-vertical timer">
            <strong v-if="remainingTime">{{ $t('orderB2b.paymentExpireIn') }} {{ remainingTime }}</strong>
            <strong v-if="!remainingTime">{{ $t('orderB2b.paymentTransactionExpired') }}</strong>
          </ion-text>
        </ion-row>
      </div>
      <!-- PAYMENT SUCCEED -->
      <div v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.SUCCEEDED">
        <ion-img
          :src="PAYMENT_STATUS_ICONS[paymentInfo.status.toUpperCase()]"
          class="payment-status-icon mt-4"
        />
        <ion-text color="primary" class="mt-6">
          <h2>{{ $t('orderB2b.paymentSuccess') }}</h2>
        </ion-text>
      </div>
      <!-- PAYMENT FAILED -->
      <div v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.FAILED" class="px-3">
        <ion-img
          :src="PAYMENT_STATUS_ICONS[paymentInfo.status.toUpperCase()]"
          class="payment-status-icon mt-4"
        />
        <ion-text color="danger" class="mt-6">
          <h2>{{ $t('orderB2b.paymentFail') }}</h2>
        </ion-text>
        <ion-text class="mt-5">
          {{ $t('orderB2b.paymentFailMessage') }}
        </ion-text>
      </div>
      <ion-card
        v-if="
          paymentMethodName === 'paynow' ||
          (paymentMethodName !== 'paynow' &&
            (paymentInfo.status === PAYMENT_TRANSACTION_STATUS.SUCCEEDED ||
              paymentInfo.status === PAYMENT_TRANSACTION_STATUS.FAILED))
        "
        class="mt-4"
      >
        <ion-card-content>
          <ion-item>
            <ion-grid>
              <ion-row>
                <ion-col class="text-grey">{{ $t('orderB2b.orderNumber') }}</ion-col>
                <ion-col class="ion-text-right fw-500">#{{ orderId }}</ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="text-grey">{{ $t('orderB2b.paymentMethod') }}</ion-col>
                <ion-col class="ion-text-right fw-500">{{ setPaymentMethod(paymentInfo) }}</ion-col>
              </ion-row>
              <ion-row v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.SUCCEEDED">
                <ion-col class="text-grey">{{ $t('orderB2b.paymentTime') }}</ion-col>
                <ion-col class="ion-text-right fw-500">{{ formatDateTime(paymentInfo.updated_at) }}</ion-col>
              </ion-row>
              <ion-row v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.PENDING">
                <ion-col class="text-grey">{{ $t('orderB2b.paymentTime') }}</ion-col>
                <ion-col class="ion-text-right fw-500">{{ formatDateTime(paymentInfo.created_at) }}</ion-col>
              </ion-row>
              <ion-row v-if="paymentMethodName !== 'paynow'">
                <ion-col class="text-grey">{{ $t('orderB2b.recipient') }}</ion-col>
                <ion-col class="ion-text-right fw-500">{{ paymentInfo.recipient_name }}</ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
          <ion-item lines="none">
            <ion-grid>
              <ion-row>
                <ion-col class="fw-700">{{ $t('orderB2b.totalAmount') }}</ion-col>
                <ion-col class="ion-text-right">
                  <span
                    class="text-primary"
                    v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.SUCCEEDED"
                    >{{ $t('paid') }}</span
                  >
                  <span
                    class="text-warning-400"
                    v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.PENDING"
                    >{{ $t('orderB2b.pendingPayment') }}</span
                  >
                  <span class="text-danger" v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.FAILED">{{
                    $t('paid_payment')
                  }}</span>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col
                  class="ion-text-right fw-700"
                  v-if="paymentInfo.status !== PAYMENT_TRANSACTION_STATUS.FAILED"
                >
                  <strong>{{ user.currency_symbol }}{{ paymentInfo.amount.toFixed(2) }}</strong>
                </ion-col>
                <ion-col
                  class="ion-text-right fw-700"
                  v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.FAILED"
                >
                  <strong>{{ user.currency_symbol }}0.00</strong>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
          <div v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.SUCCEEDED">
            <ion-button
              expand="block"
              class="btn"
              @click="goTo(`/b2b/order/purchase/order-detail/${orderId}`)"
              >{{ $t('orderB2b.viewOrderDetails') }}</ion-button
            >
            <ion-button
              class="btn-outline mt-2"
              fill="outline"
              expand="block"
              @click="goTo('/b2b/main/home')"
              >{{ $t('orderB2b.backToHome') }}</ion-button
            >
          </div>
          <div v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.PENDING">
            <div v-if="paymentInfo.paymentType?.name === 'paynow'" class="d-flex flex-column">
              <ion-button class="btn" expand="block" @click="processCheckoutWithPaynowLink">{{
                $t('orderB2b.viewQRCode')
              }}</ion-button>
              <ion-button
                class="btn-outline mt-2"
                fill="outline"
                expand="block"
                @click="setOpenCancelConfirmation(true)"
                >{{ $t('orderB2b.cancelPaymentTransaction') }}</ion-button
              >
            </div>
            <div v-else class="d-flex flex-column">
              <ion-button
                class="btn"
                expand="block"
                @click="goTo(`/b2b/order/purchase/order-detail/${orderId}`)"
                >{{ $t('orderB2b.viewOrderDetails') }}</ion-button
              >
              <ion-button
                class="btn-outline mt-2"
                fill="outline"
                expand="block"
                @click="setOpenLeaveConfirm(true)"
                >{{ $t('orderB2b.cancelPaymentTransaction') }}</ion-button
              >
            </div>
          </div>
          <div v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.FAILED">
            <ion-button
              class="btn-outline"
              fill="outline"
              expand="block"
              @click="goTo(`/b2b/order/purchase/order-detail/${orderId}`)"
              >{{ $t('orderB2b.viewOrderDetails') }}</ion-button
            >
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
    <ion-content class="skeleton" v-if="loading">
      <ion-row>
        <ion-col>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
      </ion-row>
    </ion-content>
    <!-- modal online payment -->
    <ion-modal
      :is-open="isOpenCancelConfirmation"
      @didDismiss="setOpenCancelConfirmation(false)"
      class="modal-confirm"
      :backdropDismiss="true"
    >
      <modal-confirm-cancel-payment
        @on-no="setOpenCancelConfirmation(false)"
        @on-yes="cancelPaymentTransaction"
      ></modal-confirm-cancel-payment>
    </ion-modal>
    <!-- modal cancel order confirm -->
    <ion-modal
      :is-open="isOpenLeaveConfirmRef"
      css-class="modal-confirm"
      @didDismiss="setOpenLeaveConfirm(false)"
      :backdropDismiss="false"
    >
      <ModalOrderConfirm
        :title="$t('cancel_payment') + '?'"
        :subTitle="$t('your_order_will_be_cancelled') + '.'"
        :isCancelPayment="true"
        @on-yes="cancelPaymentIntentTransaction"
        @on-no="setOpenLeaveConfirm(false)"
      ></ModalOrderConfirm>
    </ion-modal>
    <ion-loading
      :is-open="isOpenRef"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
  </ion-page>
</template>
<script>
import { apolloClient } from '@/main';
import { PAYMENT_STATUS_ICONS } from '@/modules/b2b/constants';
import {
  cancelPrepayment,
  cancelStripePaymentIntent,
  paymentTransactionDetailsForOrder
} from '@/modules/b2b/services/graphql';
import { ACTIONS as ACTIONS_CART } from '@/modules/b2b/store/cart/actions';
import { ACTIONS } from '@/modules/b2b/store/orders/purchase/actions';
import ModalOrderConfirm from '@/modules/shared/views/cart/NewModalOrderConfirm.vue';
import { PAYMENT_TRANSACTION_STATUS } from '@/services/shared/helper/constants';
import { useDateFormatter } from '@/usecases/global';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { alertController, getPlatforms, isPlatform, toastController } from '@ionic/vue';
import dayjs from 'dayjs';
import { checkmarkCircleOutline, close, closeCircleOutline } from 'ionicons/icons';
import { computed, defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';
import ModalConfirmCancelPayment from './ModalConfirmCancelPayment.vue';
const { mapActions } = createNamespacedHelpers('b2b/purchase');
const { mapActions: mapActionsCart } = createNamespacedHelpers('b2b/cart');

export default defineComponent({
  name: 'PaymentTransactionDetails',
  components: {
    ModalConfirmCancelPayment,
    ModalOrderConfirm
  },
  props: {
    orderIdNumber: {
      type: String,
      default: ''
    }
  },
  inject: ['$storage'],
  setup() {
    const ionRouter = inject('navManager');
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const loading = ref(true);
    const orderId = ref(null);
    const cronGetPaymentStatus = ref(null);
    const user = ref(null);
    const { formatDateTime } = useDateFormatter();
    const setLoading = (value) => (loading.value = value);

    const isOpenRef = ref(false);
    const setOpenLoading = (value) => (isOpenRef.value = value);

    const paymentInfo = ref({});
    const setPaymentInfo = (data) => (paymentInfo.value = data);
    const remainingTime = ref('00 HRS 00 MINS 00 SECS');

    const isOpenCancelConfirmation = ref(false);
    const setOpenCancelConfirmation = (data) => (isOpenCancelConfirmation.value = data);

    const isOpenLeaveConfirmRef = ref(false);
    const setOpenLeaveConfirm = (state) => (isOpenLeaveConfirmRef.value = state);

    const retryCount = ref(0);
    const increaseRetryCount = () => (retryCount.value += 1);

    const setPaymentMethod = (paymentValue) => {
      return paymentValue.paymentType.name === 'paynow'
        ? paymentValue.paymentType.name
        : paymentValue.card_info.brand + ' (' + paymentValue.card_info.last4 + ')';
    };

    const historyState = ref('');
    const isShowCloseButton = ref(false);

    const setIntervalGettingPaymentTransactionStatus = async (callbackWhenFinished) => {
      cronGetPaymentStatus.value = setInterval(async () => {
        increaseRetryCount();

        const { data, errors } = await apolloClient.mutate({
          mutation: paymentTransactionDetailsForOrder,
          variables: {
            orderId: parseInt(orderId.value)
          }
        });

        if (errors) return;

        const paymentTransaction = data.paymentTransactionDetailsForOrder;
        setPaymentInfo(paymentTransaction);

        if (
          paymentTransaction.status === PAYMENT_TRANSACTION_STATUS.FAILED ||
          paymentTransaction.status === PAYMENT_TRANSACTION_STATUS.SUCCEEDED
        ) {
          if (callbackWhenFinished) {
            callbackWhenFinished();
          }

          cronGetPaymentStatus.value ? clearInterval(cronGetPaymentStatus.value) : null;
          setLoading(false);
        }
      }, 5000);
    };

    const getPaymentTransactionDetails = async () => {
      // check checkout session key
      try {
        const { data } = await apolloClient.mutate({
          mutation: paymentTransactionDetailsForOrder,
          variables: {
            orderId: parseInt(orderId.value)
          }
        });

        if (data?.paymentTransactionDetailsForOrder) {
          setPaymentInfo(data.paymentTransactionDetailsForOrder);

          // process countdown remaining time for payment
          if (data.paymentTransactionDetailsForOrder.status === PAYMENT_TRANSACTION_STATUS.PENDING) {
            // start checking order status
            // long pooling to get payment transaction status changes
            setIntervalGettingPaymentTransactionStatus();
          }
        } else {
          goTo('/b2b/main/home');
        }
      } catch (e) {
        console.log(`Payment transaction error: ${e.message}`);
        goTo('/b2b/main/home');
      } finally {
        if (paymentMethodName.value === 'paynow') {
          setLoading(false);
        } else if (paymentInfo.value.status !== PAYMENT_TRANSACTION_STATUS.PENDING) {
          setLoading(false);
        }
      }
    };

    const cancelPaymentIntentTransaction = async () => {
      if (cronGetPaymentStatus.value) clearInterval(cronGetPaymentStatus.value);
      if (!paymentInfo.value?.payment_intent_id) return;
      setOpenLoading(true);

      // call cancel payment transaction API
      const { data, errors } = await apolloClient.mutate({
        mutation: cancelStripePaymentIntent,
        variables: {
          stripePaymentIntentId: paymentInfo.value?.payment_intent_id
        }
      });

      if (data.cancelStripePaymentIntent) {
        // delay for a while for stripe webhook is triggered
        setTimeout(async () => {
          setOpenLoading(false);
          setOpenLeaveConfirm(false);
          openToast('danger', closeCircleOutline, t('transaction_has_been_cancelled'));
          await getPaymentTransactionDetails();
        }, 1000);
      } else {
        // show error message
        setOpenLoading(false);
        setOpenLeaveConfirm(false);
        presentAlert('Cancel Payment Failed', `${JSON.stringify(errors[0]?.message)}`);
      }
    };

    const goTo = (pathTarget) => {
      router.push(pathTarget);
    };

    const openToast = async (color, icon, message) => {
      const toast = await toastController.create({
        mode: 'ios',
        color: color,
        position: 'top',
        icon: icon,
        message: message,
        cssClass: 'toast-custom-class',
        duration: 2000
      });
      return toast.present();
    };

    const presentAlert = async (tittle, message) => {
      const alert = await alertController.create({
        header: tittle,
        message: message,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              goTo('/b2b/main/home');
            }
          }
        ]
      });

      await alert.present();
    };

    const paymentMethodName = computed(() => paymentInfo.value?.paymentType?.name?.toLowerCase() || '');

    return {
      close,
      checkmarkCircleOutline,
      closeCircleOutline,
      ionRouter,
      router,
      loading,
      orderId,
      cronGetPaymentStatus,
      user,
      formatDateTime,
      setLoading,
      paymentInfo,
      setPaymentMethod,
      isOpenLeaveConfirmRef,
      setOpenLeaveConfirm,
      route,
      dayjs,
      PAYMENT_TRANSACTION_STATUS,
      PAYMENT_STATUS_ICONS,
      remainingTime,
      setPaymentInfo,
      isOpenCancelConfirmation,
      setOpenCancelConfirmation,
      InAppBrowser,
      isOpenRef,
      setOpenLoading,
      cancelPaymentIntentTransaction,
      getPaymentTransactionDetails,
      historyState,
      isShowCloseButton,
      goTo,
      openToast,
      presentAlert,
      paymentMethodName,
      retryCount,
      increaseRetryCount,
      setIntervalGettingPaymentTransactionStatus
    };
  },
  async ionViewDidEnter() {
    this.setOpenLoading(true);
    this.orderId = this.route.params.orderId;
    if (!this.orderId && isNaN(this.orderId)) {
      this.goTo('/b2b/main/home');
    }

    this.user = await this.$storage.getUser();
    this.historyState = window.history.state?.back;
    this.isShowCloseButton =
      this.historyState?.includes('/b2b/order') || this.historyState?.includes('/b2b/notifications');

    await this.getPaymentTransactionDetails();
    this.setOpenLoading(false);
  },

  async ionViewWillLeave() {
    clearInterval(this.cronGetPaymentStatus);
    const threeDSecureData = {
      isRedirect3DSecure: false,
      paymentOrderId: null
    };
    await this[ACTIONS_CART.STORE_3D_SECURE_PROCESS](threeDSecureData);
  },

  beforeUnmount() {
    if (this.cronGetPaymentStatus) clearInterval(this.cronGetPaymentStatus);
  },

  watch: {
    async retryCount() {
      if (this.retryCount >= 5) {
        if (
          this.paymentInfo?.status === PAYMENT_TRANSACTION_STATUS.PENDING &&
          this.paymentMethodName !== 'paynow'
        ) {
          await this.cancelPaymentIntentTransaction();
          this.setLoading(false);
        }
      }
    }
  },

  methods: {
    ...mapActions([ACTIONS.GET_PURCHASE]),
    ...mapActionsCart([ACTIONS_CART.STORE_3D_SECURE_PROCESS]),
    async processCheckoutWithPaynowLink() {
      if (
        getPlatforms().includes('mobileweb' || 'pwa' || 'desktop') ||
        (!isPlatform('ios') && !isPlatform('android'))
      ) {
        // handle for web
        window.location.href = this.paymentInfo.payment_link;
      } else if (isPlatform('ios')) {
        // handle for mobile
        await this.$browser.open({
          toolbarColor: '#ffffff',
          presentationStyle: 'fullscreen',
          url: this.paymentInfo.payment_link
        });

        // long pooling to get payment transaction status changes
        this.setIntervalGettingPaymentTransactionStatus(async () => {
          this.$browser.close();
          this.$browser.removeAllListeners();
        });

        await this.$browser.addListener('browserFinished', async () => {
          // remove interval
          this.cronGetPaymentStatus ? clearInterval(this.cronGetPaymentStatus) : null;
          // remove listener
          this.$browser.removeAllListeners();
          await this.getPaymentTransactionDetails();
        });
      } else if (isPlatform('android')) {
        this.$browser.open({
          toolbarColor: '#ffffff',
          presentationStyle: 'fullscreen',
          url: this.paymentInfo.payment_link
        });
      }
    },

    async cancelPaymentTransaction() {
      this.setOpenCancelConfirmation(false);
      this.setOpenLoading(true);

      // call cancel payment transaction API
      const { data } = await apolloClient.mutate({
        mutation: cancelPrepayment,
        variables: {
          stripeCheckoutSessionId: this.paymentInfo.checkout_session_id
        }
      });

      if (data.cancelPrepayment) {
        // delay for a while for stripe webhook is triggered
        setTimeout(() => {
          this.setOpenLoading(false);
          this.goTo(`/b2b/order/purchase/order-detail/${this.orderId}`);
        }, 1000);
      } else {
        // show error message
        this.setOpenLoading(false);
        console.log(`cancel prepayment error ${JSON.stringify(data)}`);
      }
    },

    async onCloseButton() {
      await this[ACTIONS.GET_PURCHASE]({
        params: {
          offset: 0,
          isIncludeTotalCount: true
        }
      });
      if (this.cronGetPaymentStatus) clearInterval(this.cronGetPaymentStatus);
      if (this.historyState?.includes('/b2b/order')) {
        this.goTo(`/b2b/order`);
      } else {
        this.$router.back();
      }
    }
  }
});
</script>
<style lang="scss" scoped>
ion-content {
  --background: #f5f5f5;
  text-align: center;
}
.checkmark-done {
  width: 33px;
  height: 33px;
}
.payment-status-icon {
  height: 100px;
}
ion-text {
  width: 100%;
}
ion-content {
  --overflow: hidden;
}
.expiring-icon {
  width: 30px;
  margin: auto;
  margin-right: 10px;
}
.timer {
  width: auto;
  margin: auto;
  margin-left: 0px;
}
.modal-confirm {
  --width: calc(100% - 3rem);
  --height: 35%;
  &::part(content) {
  }
}
.text-grey {
  color: var(--grey-600, #757575);
}
.btn-outline {
  height: 42px;
  text-transform: capitalize;
  --border-radius: 10px;
  --color: #00676a;
}
.btn {
  height: 42px;
  text-transform: capitalize;
  --border-radius: 10px;
  --background: #00676a;
  --color: white;
}
.modal-confirm {
  --width: calc(100% - 5rem);
  --height: fit-content;
  &::part(content) {
    border-radius: 12px;
  }
}
ion-item {
  --padding-start: 0px;
  --padding-end: 0px;
}

ion-grid {
  --ion-grid-padding: 0px;
}
</style>
